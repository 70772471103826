const ErrorPage = () => {
    return(
        <div>
            <span style={{color: 'red'}}>ERROR PAGE</span>
            <hr></hr>
            <h1 style={{textAlign: 'center'}}>404. Error route!</h1>
        </div>
    )
} 

export default ErrorPage 